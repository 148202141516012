import PropTypes from "prop-types"
import { MdClose, MdChevronRight } from "react-icons/md"
import { TbPlus } from "react-icons/tb";
import { mediaPropTypes, linkPropTypes, buttonLinkPropTypes } from "/utils/types"
import { useLockBodyScroll } from "/utils/hooks"
import { getButtonAppearance } from "/utils/button"
import ButtonLink from "./button-link"
import NextImage from "./image"
import CustomLink from "./custom-link"
import gsap from "gsap"
import React from "react"
import LocaleSwitch from "../locale-switch";

const MobileNavMenu = ({ pageContext ,navbar, closeSelf }) => {
  // Prevent window scroll while mobile nav menu is open
  const timeline = React.useRef()
  const ref = React.useRef()
  useLockBodyScroll()

  const closeMenu = () => {
    timeline.current.fromTo(ref.current, {
      clipPath:"polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    },{
      clipPath:"polygon(0 0, 100% 0, 100% 0%, 0% 0%)",
      onComplete: ()=>{
        closeSelf();
      }
    })
  }

  function openLink(e) {
    e.preventDefault();
    let all = e.target.closest(".dropdown-menu-mobile-button");
    let parents = document.querySelectorAll(".dropdown-menu-mobile-button");
    let parent = e.target.closest(".dropdown-menu-mobile-button");
    let addClass = !parent.classList.contains("open");

    parents.forEach((el)=>{
      el.classList.remove("open")
    })
    if(addClass){
      parent.classList.add("open")
    }
    if(addClass){
      gsap.to(parent.querySelector(".dropdown-menu-mobile-button-content"),{
        clipPath:"polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        height:"auto"
      })

      gsap.to(document.querySelectorAll(".dropdown-menu-mobile-button:not(.open) .dropdown-menu-mobile-button-content"),{
        clipPath:"polygon(0 0, 100% 0, 100% 0%, 0% 0%)",
        height:0
      })
    }else{
      gsap.to(document.querySelectorAll(".dropdown-menu-mobile-button .dropdown-menu-mobile-button-content"),{
        clipPath:"polygon(0 0, 100% 0, 100% 0%, 0% 0%)",
        height:0
      })
    }
    // console.log(document.querySelector(".dropdown-menu-mobile-button:not(.open) .dropdown-menu-mobile-button-content"))
    
  }


  React.useEffect(()=>{
    timeline.current = gsap.timeline()

    gsap.set(".dropdown-menu-mobile-button-content",{
      clipPath:"polygon(0 0, 100% 0, 100% 0%, 0% 0%)",
      height:0,
    })
    timeline.current.fromTo(ref.current, {
      clipPath:"polygon(0 0, 100% 0, 100% 0%, 0% 0%)",
    },{
      clipPath:"polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
    })

    return () => {
      timeline.current.kill();
    }
  },[])

  return (
    <div className="fixed top-0 left-0 z-10 w-screen pb-6 overflow-y-scroll bg-white" ref={ref} style={{ height:"100dvh"}}>
    <div className="navbar-background noise animation" style={{backgroundImage: `url("/noise-web.webp")`, position:"absolute", top:"0", bottom:"0", opacity:"0.2", zIndex:"-1", transition: "0.3s"}}></div>
    <div className="w-full h-full navbar-background" style={{background: "#e5e5e5", position:"absolute", top:"0", bottom:"0", opacity:"1", zIndex:"-2", transition: "0.3s"}}></div>
      <div className="container flex flex-col justify-start h-full">
        {/* Top section */}
        <div className="flex flex-row items-center justify-between py-4">
          {/* Company logo */}
          <CustomLink link={{url:"/"}} extraFunction={closeSelf}>
            <NextImage width="130" height="33" media={navbar.logo} style={{filter:"invert(1)"}} />
          </CustomLink>
          {/* Close button */}
          <button onClick={closeMenu} className="py-0">
            <MdClose className="w-auto h-10" />
          </button>
        </div>
        {/* Bottom section */}
        <div className="flex flex-col justify-start w-full">
          <ul className="flex flex-col items-baseline gap-5 mb-10 text-xl list-none">
            {navbar.links.map((navLink) => {
              if(!navLink.isDropdown){
                return (
                  <li key={navLink.id} className="block w-full">
                    <CustomLink link={navLink} extraFunction={closeSelf}>
                      <div className="flex flex-row items-center justify-between py-3 hover:text-gray-900" style={{borderTop:"1px solid #91919188", fontWeight:"500"}}>
                        <span>{navLink.text}</span>
                        {/* <MdChevronRight className="w-auto h-8" /> */}
                        {/* <TbPlus className="w-auto h-6" /> */}
                      </div>
                    </CustomLink>
                  </li>
                )
              }
              return (
                <li key={navLink.id} className="block w-full dropdown-menu-mobile-button">
                  <div className="" extraFunction={closeSelf} onClick={openLink}>
                    <div className="flex flex-row items-center justify-between py-3 hover:text-gray-900" style={{borderTop:"1px solid #91919188", fontWeight:"500"}}>
                      <span>{navLink.text}</span>
                      {/* <MdChevronRight className="w-auto h-8" /> */}
                      <TbPlus className="w-auto h-6" />
                    </div>
                  </div>
                  <div className="dropdown-menu-mobile-button-content">
                    {navLink.links.map((navSubLink) => {
                      return (
                        <li key={navSubLink.id} className="block w-full">
                          <CustomLink link={navSubLink} extraFunction={closeSelf}>
                            <div className="flex flex-row items-center justify-between py-3 hover:text-gray-900" style={{fontWeight:"400"}}>
                              <span>{navSubLink.text}</span>
                              {/* <MdChevronRight className="w-auto h-8" /> */}
                              {/* <TbPlus className="w-auto h-6" /> */}
                            </div>
                          </CustomLink>
                        </li>
                      )
                    })}
                  </div>
                </li>
              )
            })}
          </ul>
          <LocaleSwitch pageContext={pageContext}/>
          {/* <ButtonLink
            button={navbar.button}
            appearance={getButtonAppearance(navbar.button.type, "light")}
          /> */}
        </div>
      </div>
    </div>
  )
}

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  closeSelf: PropTypes.func,
}

export default MobileNavMenu
