// import NavBar from '../components/NavBar'
// import '../styles/index.scss'

// function MyApp({ Component, pageProps }) {
//   return (
//     <>
//       <NavBar />
//       <div className='container'>
//         <Component {...pageProps} />
//       </div>
//     </>
//   )
// }

// export default MyApp


import App from "next/app"
import Head from "next/head"
import ErrorPage from "next/error"
import { useRouter } from "next/router"
import { DefaultSeo } from "next-seo"
import { getStrapiMedia } from "/utils/media"
import { getGlobalData } from "/utils/api"
import "/styles/index.css"
import "/styles/index.scss"
import TransitionElementContextProvider from "../context/transitionElementContext"
import TransitionElement from "../components/transition-element"
import CustomCursor from "../components/elements/customCursor"
import MouseContextProvider from "../context/mouseContext";
import PageTransition from "../components/page-transition"
import Navbar from "../components/elements/navbar"
import Footer from "../components/elements/footer"
import { Color, Vector2 } from "three"
import React from "react"
import dynamic from "next/dynamic"

// import GradientBackground from "../components/gradient/GradientBackground"
const GradientBackground = dynamic(() => import('../components/gradient/GradientBackground'), {
  loading: () => <p>A carregar...</p>,
})



const MyApp = ({ Component, pageProps }) => {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const router = useRouter();
  // console.log("opacity 0", router.asPath)
  const [opacityFactor, setOpacityFactor] = React.useState(["/projects", "/events", "/articles"].includes(router.asPath) ? 0.0 : 1.0);
  // const opacityFactor = React.useRef(0.0);

  // var startTime = Date.now();
  // var frame = 0;

  // function tick() {
  //   var fps = document.getElementById("fps");
  //   var time = Date.now();
  //   frame++;
  //   if (time - startTime > 1000) {
  //       fps.innerHTML = (frame / ((time - startTime) / 1000)).toFixed(1);
  //       startTime = time;
  //       frame = 0;
  //   }
  //   window.requestAnimationFrame(tick);
  // }
  // React.useEffect(()=>{
  //   setIsInitialLoad(false)
  // },[isInitialLoad])


  // React.useEffect(()=>{
  //   console.log("renderApp")
  // })

  // React.useEffect(()=>{
  //   // tick();
  // },[])

  // Extract the data we need
  // const { global } = pageProps
  // console.log(pageProps)
  React.useEffect(() => {
    // console.log(router.asPath)
    if (["/projects", "/events", "/articles"].includes(router.asPath)) {
      // console.log("opacity 0")
      setOpacityFactor(0.0)
    } else {
      // console.log("opacity 0")
      setOpacityFactor(1.0)
    }
  }, [router.asPath])

  if (pageProps.global == null || pageProps.global.data == null) {
    // console.log("nullpage somehow")
    return <ErrorPage statusCode={404} />
  }
  // console.log(global.data)
  const { seo, favicon, field, navbar, footer } = pageProps.global.data

  const pageContext = pageProps.pageContext;


  const pos = new Vector2(0.5, 0.5);
  const color1 = new Color(pageContext.color1).convertLinearToSRGB().getHex();
  const color2 = new Color(pageContext.color2).convertLinearToSRGB().getHex();

  return (
    <>
      {/* Favicon */}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {/* Global site metadata */}
      <DefaultSeo
        titleTemplate={`%s | ${pageProps.global.data.metaTitleSuffix}`}
        title={seo.metaTitle}
        description={seo.metaDescription}
        openGraph={{
          images: Object.values(seo.metaImage.formats).map((image) => {
            return {
              url: getStrapiMedia(image.url),
              width: image.width,
              height: image.height,
            }
          }),
        }}
        twitter={{
          ...(seo.twitterCardType && { cardType: seo.twitterCardType }),
          // Handle is the twitter username of the content creator
          ...(seo.twitterUsername && { handle: seo.twitterUsername }),
        }}
      />
      {/* <div style={{position:"fixed", top:"100px", right:"100px", zIndex:"9999999999"}}><span id="fps">--</span> FPS</div> */}
      {/* Display the content */}
      <div className="min-h-screen background-color">
        <TransitionElementContextProvider location={router.asPath}>
          <TransitionElement />
          <MouseContextProvider>
            <CustomCursor />

            <PageTransition path={router.asPath}>
              <div className="relative min-h-screen background-color" id="#main">
                <div id="scroll-animation-helper" style={{ position: "absolute", top: "0", left: "0", height: "100svh", zIndex: "-30" }}></div>
                <div id="background-wrapper">
                  {opacityFactor > 0 ? (
                    <GradientBackground colorStart={color1} colorEnd={color2} positionBlob={pos} scale={1.5} opacityFactor={opacityFactor} circleScale={15} />
                  ) : ""}
                  <div className="noise" style={{ backgroundImage: `url("/noise-web.webp")`, pointerEvents: "none" }}></div>
                </div>

                <Navbar navbar={navbar} pageContext={pageContext} isBlack={false} />

                <div className="w-full layout-wrapper">
                  <Component {...pageProps} />
                </div>

                {/* <Footer footer={footer} colorFooter={pageContext.colorFooter} /> */}
              </div>
            </PageTransition>


            {/* Aligned to the bottom */}
            {/* <Footer footer={footer} colorFooter={pageContext.colorFooter} /> */}

          </MouseContextProvider>
        </TransitionElementContextProvider>
      </div>
    </>
  )
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  // console.log("\n\n\n\n\n\n\n\n\n\n\n INITIAL PROPS \n\n\n\n\n\n\n\n\n\n\n")
  const appProps = await App.getInitialProps(appContext)
  console.log(appContext.router.locale, "\n\n\n\n\n\n\n");
  const globalLocale = await getGlobalData(appContext.router.locale ?? "en")
  // console.log(globalLocale.data)

  return {
    ...appProps,
    pageProps: {
      global: globalLocale.data,
    },
  }
}

export default MyApp
