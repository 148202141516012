import { useEffect, useState, useRef } from "react"
import { useRouter } from "next/router"
import PropTypes from "prop-types"
import Link from "next/link"

import Cookies from "js-cookie"
import { MdExpandMore } from "react-icons/md"
import WorldIcon from "./icons/world"

import { useOnClickOutside } from "../utils/hooks"

const LocaleSwitch = ({ navbarBlack, pageContext }) => {
  const isMounted = useRef(false)
  const select = useRef()
  const router = useRouter()
  const { pathname, asPath, query } = router
  const [locale, setLocale] = useState()
  const [showing, setShowing] = useState(false)

  const handleLocaleChange = async (selectedLocale) => {
    // Persist the user's language preference
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    Cookies.set("NEXT_LOCALE", selectedLocale)
    setLocale(selectedLocale)
  }

  const handleLocaleChangeRef = useRef(handleLocaleChange)
  useOnClickOutside(select, () => setShowing(false))

  useEffect(() => {
    const localeCookie = Cookies.get("NEXT_LOCALE")
    if (!localeCookie) {
      handleLocaleChangeRef.current(router.locale)
    }

    const checkLocaleMismatch = async () => {
      if (
        !isMounted.current &&
        localeCookie &&
        localeCookie !== pageContext.locale
      ) {
        // // Redirect to locale page if locale mismatch
        // const localePage = getLocalizedPage(localeCookie, pageContext)
        // console.log(pageContext, localePage)

        // router.push(
        //   `${localizePath({ ...pageContext, ...localePage })}`,
        //   `${localizePath({ ...pageContext, ...localePage })}`,
        //   { locale: localePage.locale }
        // )

        // change just the locale and maintain all other route information including href's query
        console.log("TESTING THIS", pathname, query, asPath, localeCookie)
        router.push({ asPath, query }, asPath, { locale: localeCookie })
      }
      
      setShowing(false)
    }

    setLocale(localeCookie || router.locale)
    checkLocaleMismatch()

    return () => {
      isMounted.current = true
    }
  }, [locale, router, pageContext])

  return (
    <div ref={select} className="relative">
      <button
        type="button"
        className="hover:bg-primary-50 hover:text-primary-600 focus:bg-primary-50 focus:text-primary-600 focus:outline-none flex items-center justify-between px-2 py-2 cursor-pointer h-full rounded-md min-w-20"
        onClick={() => setShowing(!showing)}
      >
        <WorldIcon navbarBlack={navbarBlack}/>
        <span className={`uppercase whitespace-nowrap ${navbarBlack || navbarBlack === undefined ? "text-black" : "text-white"}`}>{locale}</span>
        <MdExpandMore className={`ml-1 text-primary-600 ${navbarBlack || navbarBlack === undefined ? "text-black" : "text-white"}`} />
      </button>
      <div
        className={`w-full bg-white p-1 mt-1 shadow-lg rounded-md ${
          showing ? "absolute" : "hidden"
        }`}
      >
        {router.locales &&
          router.locales.map((el, index) => {
            console.log(el)
            return (
              <Link
                href={asPath}
                key={el}
                locale={el}
                role="option"
                passHref
              >
                <p
                  onClick={() => handleLocaleChange(el)}
                  className="uppercase hover:bg-primary-50 hover:text-primary-600 text-black cursor-pointer p-2 rounded-md text-center hover:text-primary-600"
                >
                  {el}
                </p>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

LocaleSwitch.propTypes = {
  initialLocale: PropTypes.string,
}

export default LocaleSwitch
